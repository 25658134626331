<template>
    <form @submit.prevent="validateForm" v-if="isForm">
        <div class="md:flex gap-4">
            <div class="input-div">
                <input
                    type="text"
                    v-model="formData.name"
                    :placeholder="localizationData.name"
                />
            </div>
            <div class="input-div">
                <input
                    type="text"
                    v-model="formData.azienda"
                    :placeholder="localizationData.company"
                />
            </div>
        </div>
        <div class="md:flex gap-4">
            <div class="input-div">
                <input
                    type="text"
                    v-model="formData.phone"
                    @input="restrictChars"
                    :placeholder="localizationData.phone"
                />
            </div>
            <div class="input-div">
                <input
                    type="text"
                    v-model="formData.email"
                    :placeholder="localizationData.email"
                />
            </div>
        </div>
        <div>
            <textarea
                name=""
                v-model="formData.messagge"
                id=""
                cols="30"
                rows="10"
                :placeholder="localizationData.messagge"
            ></textarea>
        </div>
        <div class="button">
            <button>{{ localizationData.send }}</button>
        </div>
        <div class="text-[red] text-[16px] italic text-center mt-[10px]">
            {{ error }}
        </div>
    </form>
    <div v-if="isThankyou" class="mt-[60px]">
        <p
            class="text-[#ffffff] md:mt-[10px] text-[25px] md:text-[35px] text-center bold euro-stile-std-demi leading-[35px] mt-[10px]"
        >
            {{ localizationData.thankyou }}
        </p>
    </div>
</template>

<script>
import axios from "axios";
import * as Sentry from "@sentry/vue";

export default {
    props: ["localizationData"],
    data() {
        return {
            isForm: true,
            formData: {
                name: "",
                azienda: "",
                phone: "",
                email: "",
                messagge: "",
            },
            errors: [],
            error: "",
            isThankyou: false,
        };
    },
    methods: {
        restrictChars() {
            // Replace any non-numeric characters with an empty string
            this.formData.phone = this.formData.phone.replace(/\D/g, "");
        },
        validateForm(e) {
            e.preventDefault();
            this.errors = [];
            if (!this.formData.name) {
                this.error = this.localizationData.errors.name;
                this.errors.push(this.localizationData.errors.name);
            } else if (!this.formData.azienda) {
                this.error = this.localizationData.errors.company;
                this.errors.push(this.localizationData.errors.company);
            } else if (!this.formData.phone) {
                this.error = this.localizationData.errors.phone;
                this.errors.push(this.localizationData.errors.phone);
            } else if (!this.checkPhone(this.formData.phone)) {
                this.error = this.localizationData.errors.phone;
                this.errors.push(this.localizationData.errors.phone);
            } else if (!this.formData.email) {
                this.error = this.localizationData.errors.email;
                this.errors.push(this.localizationData.errors.email);
            } else if (!this.validEmail(this.formData.email)) {
                this.error = this.localizationData.errors.email;
                this.errors.push(this.localizationData.errors.email);
            } else if (!this.formData.messagge) {
                this.error = this.localizationData.errors.messagge;
                this.errors.push(this.localizationData.errors.messagge);
            } else {
                this.error = "";
            }

            if (!this.errors.length) {
                const { firstName, lastName } = this.extractNames(
                    this.formData.name
                );
                console.log("Free to go!");
                this.sendEmail(
                    firstName,
                    lastName,
                    this.formData.email,
                    this.formData.phone,
                    this.formData.messagge,
                    this.formData.azienda
                );
            }
        },
        extractNames(fullName) {
            const namesArray = fullName.split(" ");
            const firstName = namesArray[0];
            const lastName =
                namesArray.length > 1 ? namesArray[namesArray.length - 1] : "";
            return { firstName, lastName };
        },
        sendEmail(first_name, last_name, email, phone, comment, company_name) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "everset_new_contact",
            });

            const headers = {
                "Content-Type": "application/json",
                token: "ga9uvPy.fzpfS:4xX.dwSQekytk3VMV,ciVX:;A5",
            };

            const user = {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone,
                comment: comment,
                company_name: company_name,
            };
            axios
                .post(
                    "https://everset-leads-manager.production.externalcampaign.goodieweb.net/sendWebSiteEmail",
                    JSON.stringify(user),
                    {
                        headers,
                    }
                )
                .then((response) => {
                    debugger;
                    // Handle the response data here
                    if (response.data.success) {
                        this.isForm = false;
                        this.isThankyou = true;

                        console.log("done");
                    } else {
                        Sentry.captureException(
                            `Error Email Sent --> ${JSON.stringify(user)}`
                        );
                        alert("Qualcosa è andato storto! Per favore riprova!");
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                });
        },
        validEmail(e) {
            var filter =
                /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
            return String(e).search(filter) != -1;
        },
        checkPhone(phone) {
            var regex = /^[0-9]+$/;

            if (!regex.test(phone)) {
                return false;
            }

            if (
                phone == "" ||
                phone.length < 9 ||
                phone.length > 10 ||
                phone.indexOf("333333") !== -1 ||
                phone.indexOf("111111") !== -1 ||
                phone.indexOf("000000") !== -1 ||
                phone.indexOf("222222") !== -1 ||
                phone.indexOf("444444") !== -1 ||
                phone.indexOf("555555") !== -1 ||
                phone.indexOf("666666") !== -1 ||
                phone.indexOf("777777") !== -1 ||
                phone.indexOf("888888") !== -1 ||
                phone.indexOf("999999") !== -1
            ) {
                return false;
            }

            var prefix = [
                "320",
                "323",
                "324",
                "327",
                "328",
                "329",
                "330",
                "331",
                "333",
                "334",
                "335",
                "336",
                "337",
                "338",
                "339",
                "340",
                "341",
                "342",
                "343",
                "344",
                "345",
                "346",
                "347",
                "348",
                "349",
                "350",
                "351",
                "360",
                "362",
                "363",
                "366",
                "368",
                "370",
                "371",
                "373",
                "375",
                "379",
                "353",
                "377",
                "380",
                "383",
                "385",
                "388",
                "389",
                "390",
                "391",
                "392",
                "393",
                "397",
            ];
            var prefix_tel = phone.substring(0, 3);

            return prefix.indexOf(prefix_tel) > -1;
        },
    },
};
</script>

<style></style>
